import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Row} from "./Row";

export default function MaxWidthDialog({ onClose, open, setOpen, formula }) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="max-width-dialog-title">Формула и обозначения</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Row label="Формула расчёта тепловой энергии">
              {formula}
            </Row>
            <Row label="Обозначения">
              <ul>
                <li>Q, Q₁, Q₂ - тепловая энергия, Гкал.</li>
                <li>М₁, М₂ - масса теплоносителя, Т.</li>
                <li>t₁, t₂ - температура теплоносителя, °С.</li>
                <li>tₓ - температура холодной воды, °С.</li>
                <li>h₁, h₂ - удельная энтальпия теплоносителя, Ккал/кг. h₁=h(t₁), h₂=h(t₂).</li>
                <li>p₁, p₂ - давление теплоносителя, МПа.</li>
                <li>V, V₁, V₂ - объем теплоносителя, м3</li>
              </ul>
            </Row>
            <Row label="Примечание">
              Параметр  hₓ задается  предприятием-изготовителем  (tₓ  =  5  °С  по  умолчанию),  по  заказу  возможны
              другие значения.  Параметр  tₓ  доступен  для  просмотра  через  программу  TestAll
              (сервисная  программа  доступна  на  сайте <a href="https://www.pulsarm.ru/">pulsarm.ru</a>) и защищен от записи.
            </Row>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Продолжить
          </Button>
        </DialogActions>
      </Dialog>
  );
}
