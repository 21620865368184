import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function MaxWidthDialog({ onClose, open, setOpen }) {

  const helpInputRef = React.useRef();
  if (open && helpInputRef.current)
    helpInputRef.current.focus();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll="paper"
      >
        <DialogTitle id="max-width-dialog-title">Правила заполнения интерактивного листа заказа</DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>
            <ul>
              <li>Начните с заполнении информации о вас</li>
              <li>Выберите количество расходомеров, трубопровод и тип прибора</li>
              <li>В раскрывающихся вкладках обязательно заполните поля помеченные «*», поля без такой отметки не обязательны к заполнению</li>
              <li>Нажмите кнопку «Отправить расчет»</li>
            </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" innerRef={helpInputRef}>
            Понятно
          </Button>
        </DialogActions>
      </Dialog>
  );
}
