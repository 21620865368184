import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
        width: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(2),
        width: '100%',
        minHeight: 'calc(100vh - 2.5em)',
      }
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    fullWidth: {
      width: '100%'
    },
    min200: {
      minWidth: 200
    },
    AccordionDetails: {
      flexDirection: 'column'
    },
    row: {
      padding: 8
    },
    bold: {
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
      paddingRight: 20
    },
    reqTrue: {
      fontSize: 10,
      color: '#A48400'
    },
    reqFalse: {
      fontSize: 10,
      display: 'none'
    },
    paidTrue: {
      fontSize: 10,
      color: '#006AA1'
    },
    paidFalse: {
      fontSize: 10
    },
    sel: {
      backgroundImage: 'url(/green-check.png) !important',
      backgroundPositionX: 'left !important',
      backgroundPositionY: 'bottom !important',
      backgroundSize: '30% !important',
      backgroundRepeat: 'no-repeat !important'
    },
    td: {
      verticalAlign: 'middle',
      display: 'table-cell'
    },
    table: {
      display: 'table'
    },
    buttonGroup: {
      display: 'inline !important'
    },
    img: {
      maxWidth: '100%',
      width: '330px',
      height: 'auto',
    },
    spacer: {
      marginLeft: '0.7rem'
    },
    title: {
      maxWidth: '800px',
      width: '100%',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '0.7rem'
    },
    countField: {
      maxWidth: '50px',
    },
    imgPage: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain'
    },
    backdrop: {
      zIndex: 9999,
      color: '#fff',
    },
    closed: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
      maxWidth: '100%',
    }
  }
));
