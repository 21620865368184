import { Backdrop, Box, CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from '@material-ui/core/Paper';
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useReducer } from 'react';
import { Helmet } from "react-helmet";
import { Code } from "./Code";
import { Api } from "./apiProvider";
import { baseApiUrl } from "./constants";
import {
  Days,
  energyMethodList,
  kran_opt, kran_opt_special,
  massMethodList,
  Monthes,
  optionsByDia,
  optionsByDia3,
  privarka_opt, radiator_opt,
  rezba_opt,
  Table2,
  Table3,
  Temps,
  trubo_opt,
  typeTableByCount
} from "./Data";
import { Group } from "./Group";
import MaxWidthDialog from "./Help";
import HelpFormula from "./HelpFormula";
import HelpPage from "./HelpPage";
import { MyButton } from "./MyButton";
import { OptionButtons } from "./OptionButtons";
import { getReducer, history, initReducer, objectToHash } from "./Reducer";
import RegionSelect from "./RegionSelect";
import ActivitySelect from "./ActivitySelect";
import { activities } from "./activities";
import RequiredFieldDialog from "./RequiredFieldDialog";
import { Row } from "./Row";
import { SelectOptions } from "./SelectOptions";

import { useStyles } from "./Styles";
import { SwitchCount } from "./SwitchCount";
import { Table } from "./Table";
import { Text } from "./Text";

function App() {
  const classes = useStyles();

  const [openMissing, setOpenMissing] = React.useState(false);
  const [openHelp, setOpenHelp] = React.useState((history.location.pathname && history.location.pathname !== '/') ? false : true);
  const [openFormula, setOpenFormula] = React.useState(false);
  const [openPage, setOpenPage] = React.useState(false);
  const [pageSrc, setPageSrc] = React.useState('');

  const [saving, setSaving] = React.useState('');
  const [telMask, setTelMask] = React.useState(true);
  const [savingProgress, setSavingProgress] = React.useState(false);

  const [Regions, setRegions] = React.useState([]);
  const [Settings, setSettings] = React.useState({ isLoading: true });

  const isSettingsLoading = Settings.isLoading === true;
  const firstInputRef = React.useRef();

  useEffect(() => {
    const getSettings = async () => {
      const data = await Api.settings();
      setSettings(data);
      if (data?.settings?.soap !== true && data && data.number)
        set('S_NOM')(data.number);
      if (data && data.regions)
        setRegions(data.regions);
      if (openHelp === false && firstInputRef.current)
        firstInputRef.current.focus();
    };
    getSettings();
    // eslint-disable-next-line
  }, []);

  const missing = [];
  const setMissing = (label) => {
    if (!missing.includes(label))
      missing.push(label);
  };
  const memoizedReducer = React.useCallback(getReducer(), [])
  const [r, dispatch] = useReducer(
    memoizedReducer, initReducer()
  );
  console.log(r)

  const savePDF = () => {
    setSavingProgress(true);
    setSaving('Формирование документа и отправка почты...');
    const request = new Request(`${baseApiUrl}/send`, {
      method: 'POST',
      body: objectToHash(r),

    });
    return fetch(request)
      .then(response => {
        setSavingProgress(false);
        if (response.status < 400) {
          setSaving('Лист заказа успешно отправлен!');
          response.json().then((data) => {
            if (data) {
              if (data.s_nom)
                set('S_NOM')(data.s_nom);
              if (data.s_dat)
                set('S_DAT')(data.s_dat);
            }
          });
          window.dataLayer.push({ 'event': 'Form_list_zakaza' });
        }
        else
          setSaving('Ошибка отправки почты!');
      }).catch(() => {
        setSaving('Ошибка отправки почты!');
      });

  };

  const set = (type) => (id) => dispatch({ type, id });

  const typeTable = (typeTableByCount[r.count] || [])
  const toOpt = (arr, payment) => (arr || []).map((id, i) => { return { id, text: id, paid: payment && i > 0 ? true : undefined } });
  const volumeTable = toOpt(optionsByDia[r.dia]);
  const volumeTable2 = toOpt(optionsByDia[r.dia2]);
  const volumeTable3 = toOpt(optionsByDia3[r.dia3]);

  let massTable = []
  let energyTable = []
  if (r.count !== undefined && r.type !== undefined) {
    massTable = toOpt(typeTable.find(el => r.type === el.t).m)
    energyTable = toOpt(typeTable.find(el => r.type === el.t).e)
  }

  let table2filled = false;
  let table2dia2filled = false;
  let t2 = {};
  let t2dia2 = {}
  // let t2arr = [];
  // let tlimit = [];
  if ((r.dia !== undefined || r.dia !== '') && (r.volume !== undefined || r.dia !== '')) {
    table2filled = true;
    t2 = Table2.find((el) => el.du === parseInt(r.dia) && el.maxim === parseFloat(r.volume)
    /*&& el.limit === parseFloat(r.limit)*/);
    // t2arr = Table2.filter( (el) => el.du === parseInt(r.dia) && el.maxim === parseFloat(r.volume) );
    // tlimit = toOpt(t2arr.map((el) => el.limit));
  }

  // получение данных для поля предельного расхода
  let t2Limit = []
  if (r.dia === '15' && r.volume === 1.5) {
    Table2.forEach((el) => { if (String(el.du) === r.dia && el.maxim === r.volume) t2Limit.push(el.limit) })
    if (!r.limit || !t2Limit.includes(+r.limit)) r.limit = t2Limit[0] // установка дефолтного значения
  }

  if (r.dia === '20' && r.volume === 2.5) {
    Table2.forEach((el) => { if (String(el.du) === r.dia && el.maxim === r.volume) t2Limit.push(el.limit) })
    if (!r.limit || !t2Limit.includes(+r.limit)) r.limit = t2Limit[0] // установка дефолтного значения
  }



  if ((r.dia2 !== undefined || r.dia2 !== '') && (r.volume2 !== undefined || r.dia2 !== '')) {
    table2dia2filled = true;
    t2dia2 = Table2.find((el) => el.du === parseInt(r.dia2) && el.maxim === parseFloat(r.volume2)/* && el.limit === parseFloat(r.limit)*/);
  }

  let table3filled = false;
  let t3 = {};
  let t3arr = [];
  let t3minim = [];
  if ((r.dia3 !== undefined || r.dia3 !== '') && (r.volume3 !== undefined || r.dia !== '')) {
    table3filled = true;
    t3 = Table3.find((el) => el.du === parseInt(r.dia3) && el.nom === parseFloat(r.volume3) && el.minim === parseFloat(r.t3min));
    t3arr = Table3.filter((el) => el.du === parseInt(r.dia3) && el.nom === parseFloat(r.volume3));
    t3minim = toOpt(t3arr.map((el) => el.minim));
  }

  if (!r.zima) r.temp_leto = r.temp // при выключенном показателе зима/лето установить температуру воды лето===зама

  // const tminim = toOpt(t2arr.map((el) => el.minim))
  // const tpor = toOpt(t2arr.map((el) => el.por))

  let rcount = 1;
  if (r.count)
    rcount = r.count;

  let regionData = {};
  let regionId = -1;
  const disableRegions = !!(Settings && Settings.settings && Settings.settings.disableRegions);
  const disableActivity = !!(Settings && Settings.settings && Settings.settings.disableActivity);
  const disableAddress = r.s_activity !== "Проектная организация";
  const disableEmail = !!(Settings && Settings.settings && Settings.settings.disableEmail);
  const specialFields = !!(Settings && Settings.settings && Settings.settings.specialFields);
  let requiredOverride = [];
  if (Settings && Settings.settings && Settings.settings.required && Settings.settings.required.length > 0)
    requiredOverride = Settings.settings.required;

  if (disableRegions === true && Regions.length > 0) {
    [regionData] = Regions;
    regionId = 0;
  }
  else
    if (r.s_city) {
      regionId = Regions.findIndex((el) => el.name === r.s_city);
      if (regionId !== -1) {
        regionData = Regions[regionId];
      }
    }

  function getPage(number) {
    return <Button
      variant="contained"
      color="primary"
      onClick={() => {
        setPageSrc(`/page${number}.png`);
        setOpenPage(true);
      }}
      className={classes.spacer}>?</Button>;
  }

  let content = undefined;
  if (isSettingsLoading === true) {
    content = <Backdrop className={classes.backdrop} open>
      <CircularProgress color="inherit" size={50} />
    </Backdrop>
  } else if (Settings?.settings?.closed === true) {
    content = <div>
      <Helmet>
        <title>{'Ведутся технические работы! ' + (Settings && Settings.title) + 'Лист заказа'}</title>
      </Helmet>
      <CssBaseline />
      <img className={classes.closed} alt="Ведутся технические работы" src="/closed.jpg" />
    </div>;
  } else {
    content = <div>
      <Helmet>
        <title>{(Settings && Settings.title) + 'Лист заказа'}</title>
      </Helmet>
      <CssBaseline />
      <Typography component="p" variant="body2" align="center" className={classes.title}>
        <img className={classes.title} alt="title" src={Settings.logo} />
        {Settings && Settings.info}
      </Typography>
      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5" align="center">
            Лист заказа №&nbsp;
            <TextField id="s_nom" value={r.s_nom} disabled />
            &nbsp;от&nbsp;
            <TextField id="s_dat" value={r.s_dat} disabled />
          </Typography>
          <Row setMissing={setMissing} label="Количество теплосчётчиков" req={r.quantity === undefined || r.quantity === ''}>
            <Text onBlur={(e) => set('QUANTITY')(e.target.value)} text={r.quantity}
              placeholder="999" />
          </Row>
          <Row setMissing={setMissing} label="Контактное лицо" req={r.s_pok === undefined || r.s_pok === ''}>
            <Text onBlur={(e) => set('S_POK')(e.target.value)} text={r.s_pok} inputRef={firstInputRef}
              placeholder="Сидоров Пётр" />
          </Row>
          <Row setMissing={setMissing} label="Телефон"
            req={requiredOverride.findIndex((v) => v === 'phone') > -1 && (r.s_tel === undefined || r.s_tel === ''
              || (telMask && !r.s_tel.match(/^\+7\(\d{3}\)\d{3}-\d{4}$/))
            )}>
            <div style={{ width: '100%' }}>
              <Box display="flex">
                <Box p={1} flexGrow={1}>
                  <Text onBlur={(e) => { set('S_TEL')(e.target.value); }} text={r.s_tel} mask={telMask}
                    placeholder="Введите телефон" />
                </Box>
                <Box ml={1} display="inline" />
                <OptionButtons options={[{ id: true, text: "РФ" }, { id: false, text: "МИР" }]} onClick={setTelMask} selected={telMask} />
              </Box>
            </div>
          </Row>
          {
            (specialFields === true) &&
            <>
              <Row setMissing={setMissing} label="Объект" req={r.s_obj === undefined || r.s_obj === ''}>
                <Text onBlur={(e) => set('S_OBJ')(e.target.value)} text={r.s_obj}
                  placeholder="Введите название объекта, адрес" />
              </Row>
              <Row label="Название компании" setMissing={setMissing} req={r.s_firm === undefined || r.s_firm === ''}>
                <Text onBlur={(e) => set('S_FIRM')(e.target.value)} text={r.s_firm}
                  placeholder="Введите название компании" />
              </Row>
              <Row label="ИНН" setMissing={setMissing} req={r.s_inn === undefined || r.s_inn === ''}>
                <Text onBlur={(e) => set('S_INN')(e.target.value)} text={r.s_inn}
                  placeholder="ИНН" />
              </Row>
            </>
          }
          {
            (disableEmail !== true) &&
            <Row setMissing={setMissing} label="E-Mail" req={r.s_mail === undefined || r.s_mail === ''}>
              <Text onBlur={(e) => set('S_MAIL')(e.target.value)} text={r.s_mail}
                placeholder="Введите e-mail" />
            </Row>
          }
          {
            (disableRegions !== true) &&
            <Row setMissing={setMissing} label="Регион" req={r.s_city === undefined || r.s_city === ''}>
              <RegionSelect
                regions={Regions}
                onChange={(event, newInputValue) => set('S_CITY')(newInputValue)} value={r.s_city ? r.s_city : null}
                renderInput={params => <TextField {...params} label="Введите регион" fullWidth />}
              />
            </Row>
          }
          {
            !disableActivity &&
            <Row setMissing={setMissing} label="Сфера деятельности компании" req={r.s_activity === undefined || r.s_activity === ''}>
              <ActivitySelect
                activities={activities}
                onChange={(event, newInputValue) => set('S_ACTIVITY')(newInputValue)} value={r.s_activity ? r.s_activity : null}
                renderInput={params => <TextField {...params} label="Выберите сферу деятельности компании" fullWidth />}
              />
            </Row>
          }
          {
            !disableAddress &&
            <Row setMissing={setMissing} label="Адрес проектной организации" req={r.s_address === undefined || r.s_address === ''}>
              <Text onBlur={(e) => set('S_ADDRESS')(e.target.value)} text={r.s_address}
                placeholder="Укажите адрес проектной организации" />
            </Row>
          }
          <Typography component="h1" variant="h6" align="center">
            Общедомовый теплосчетчик «Пульсар» модификации У(УД)
          </Typography>
          <Row setMissing={setMissing} label="Количество расходомеров" req={r.count === undefined}>
            <OptionButtons options={[{ 'id': 1, text: '1' }, { 'id': 2, text: '2' }, { id: 3, text: '3' }]}
              selected={r.count} onClick={set('COUNT')} />
          </Row>

          <Row setMissing={setMissing} label="Тип прибора²" req={r.type === undefined}>
            <OptionButtons options={
              typeTable.reduce((acc, el) => {
                if (acc.find((e) => e.id === el.t) === undefined)
                  acc.push({ id: el.t, text: 'Тип ' + el.t });
                return acc;
              }, [])
            }
              selected={r.type}
              onClick={set('TYPE')}
            />
          </Row>
          {
            // Если количество расходомеров = 1
            (r.count === 1) && (
              <Row setMissing={setMissing} label="Трубопровод" req={r.trubo === undefined}>
                <OptionButtons
                  options={
                    trubo_opt.filter(opt => typeTable.find(el => ((el.t === r.type) && (el.s === opt.id))))
                  }
                  selected={r.trubo} onClick={set('TRUBO')} />
              </Row>
            )
          }
          {/* {<Row setMissing={setMissing} label="Возможность конфигурирования на объекте">
              <Switch checked={r.na_objekte} onChange={(event) => set('NA_OBJEKTE')(event.target.checked)}
                      color="primary"/>
          </Row>} */}
          {
            // Схемы и формулы согласно выбранному типу прибора (и согласно трубопроводу, если количество расходомеров = 1)
            typeTable
              .filter(el => (el.s === undefined || el.s === r.trubo))
              .filter(el => el.t === r.type)
              .map(({ k, t, i, d, f }) => [
                <Row setMissing={setMissing} label="Схема" key={1}>
                  <img src={i} alt={t} className={classes.img} />
                </Row>,
                !r.na_objekte &&
                <Row setMissing={setMissing} label="Формула расчёта тепловой энергии" key={2}>
                  {f}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenFormula(true);
                    }}
                    className={classes.spacer}>?</Button>
                  <HelpFormula open={openFormula} setOpen={setOpenFormula} formula={f} />
                </Row>
              ])
          }
          <Group label="1. Расходомер - тепловычислитель">
            <Row setMissing={setMissing} label="Диаметр условного прохода, мм"
              req={r.dia === undefined || r.dia === ''}>
              <SelectOptions options={Object.keys(optionsByDia).map(el => {
                return { id: el, text: el }
              })} selected={r.dia} onClick={set('DIA')} />
            </Row>
            <Row setMissing={setMissing} label="Максимальный объемный расход, м³/ч"
              req={r.volume === undefined || r.dia === ''}>
              <OptionButtons options={volumeTable} onClick={set('VOLUME')} selected={r.volume} />
            </Row>
            {
              (table2filled && t2 !== undefined) ?
                <>
                  <Row label="Минимальный объемный расход, м³/ч">
                    {t2.minim}
                  </Row>
                  <Row label="Предельный объемный расход, м³/ч">
                    {/* req={r.limit === undefined || r.dia === ''}>
              <OptionButtons options={tlimit} onClick={set('LIMIT')} selected={r.limit}/> */}

                    {t2Limit.length > 0 ? <OptionButtons options={toOpt(t2Limit)} onClick={set('LIMIT')} selected={r.limit} /> : t2.limit}

                  </Row>
                  <Row label="Порог чувствительности, м³/ч">
                    {t2.por}
                  </Row>
                </>
                : false
            }
            <Row setMissing={setMissing} label="Интерфейс" req={r.interf === undefined}>
              <OptionButtons options={toOpt(['RS485', 'M-Bus'])} onClick={set('INTERF')} selected={r.interf} />
            </Row>
            <Row setMissing={setMissing} label="Зима/лето">
              <Switch checked={r.zima !== false} onChange={(event) => set('ZIMA')(event.target.checked)}
                color="primary" />
            </Row>
            {!r.zima ?
              <Row setMissing={setMissing} label="Температура холодной воды, °С" req={r.temp === undefined}>
                <OptionButtons size="small" options={toOpt(Temps)} onClick={set('TEMP')} selected={r.temp} />
              </Row>
              :
              !r.na_objekte &&
              <>
                <Row setMissing={setMissing} label="Месяц перехода на зиму">
                  <SelectOptions options={toOpt(Monthes)} onClick={set('ZIMA_MONTH')} selected={r.zima_month} />
                </Row>
                <Row setMissing={setMissing} label="День перехода на зиму">
                  <SelectOptions options={toOpt(Days)} onClick={set('ZIMA_DAY')} selected={r.zima_day} />
                </Row>
                <Row setMissing={setMissing} label="Месяц перехода на лето">
                  <SelectOptions options={toOpt(Monthes)} onClick={set('LETO_MONTH')} selected={r.leto_month} />
                </Row>
                <Row setMissing={setMissing} label="День перехода на лето">
                  <SelectOptions options={toOpt(Days)} onClick={set('LETO_DAY')} selected={r.leto_day} />
                </Row>
                <Row setMissing={setMissing} label="Температура холодной воды зимой, °С" req={r.temp === undefined}>
                  <OptionButtons size="small" options={toOpt(Temps)} onClick={set('TEMP')} selected={r.temp} />
                </Row>
                <Row setMissing={setMissing} label="Температура холодной воды летом, °С"
                  req={r.temp_leto === undefined}>
                  <OptionButtons size="small" options={toOpt(Temps)} onClick={set('TEMP_LETO')} selected={r.temp_leto} />
                </Row>
              </>
            }
            <Row setMissing={setMissing} label="Минимальная разность температур, °С" req={r.mindiff === undefined}>
              <OptionButtons options={toOpt(['0,5', '3'])} onClick={set('MINDIFF')} selected={r.mindiff} />
            </Row>
            <Row setMissing={setMissing} label="Обратный поток" req={r.obr === undefined}>
              <Switch checked={!!r.obr} onChange={(event) => set('OBR')(event.target.checked)} color="primary" />
            </Row>
            {!r.na_objekte &&
              <Row setMissing={setMissing} label="Метод контроля масс" req={r.massmethod === undefined}>
                <OptionButtons options={
                  massTable
                } onClick={set('MASSMETHOD')}
                  selected={r.massmethod} />
                {r.massmethod &&
                  <Typography component="div" variant="caption">{massMethodList[r.massmethod]}</Typography>}
              </Row>}
            {!r.na_objekte &&
              <Row setMissing={setMissing} label="Метод контроля энергии" req={r.energymethod === undefined}>
                <OptionButtons options={
                  energyTable
                } onClick={set('ENERGYMETHOD')}
                  selected={r.energymethod} />
                {r.energymethod &&
                  <Typography component="div" variant="caption">{energyMethodList[r.energymethod]}</Typography>}
              </Row>}
            <Row setMissing={setMissing} label="Преобразователи давления" paid={r.preobr !== 'нет'}>
              <OptionButtons options={toOpt(r.obr ? ['нет'] : ['нет', 'M20', 'G1/2'], true)} onClick={set('PREOBR')}
                selected={r.preobr} /><Typography component="div" variant="caption">Резьба
                  присоединения</Typography>
            </Row>
            <Row setMissing={setMissing} label="Диапазон измерений температуры, °С" paid={r.tempizm !== '105'}>
              <OptionButtons options={toOpt(['105', '150'], true)} onClick={set('TEMPIZM')} selected={r.tempizm} />
            </Row>
            <Row setMissing={setMissing} label="Кабель в металлорукаве" paid={r.metal !== false}>
              <Switch checked={r.metal !== false} onChange={(event) => set('METAL')(event.target.checked)}
                color="primary" />
            </Row>
            {/* <Row setMissing={setMissing} label="Длина кабеля от преобразователя расхода до вычислителя, м"
              paid={r.lenv !== '0,3'}>
              <OptionButtons options={toOpt(['0,3', '1,5', '3', '6'], true)} onClick={set('LENV')} selected={r.lenv} />
              {getPage(3)}
            </Row> */}
             <Row setMissing={setMissing} label="Выносной индикатор 6м">
              <Switch checked={r.ind !== false} onChange={(event) => set('IND')(event.target.checked)}
                color="primary" />
            </Row>
            {
              !(r.type === '04' && r.trubo === 3) &&
              <Row setMissing={setMissing}
                label="Длина кабеля термопреобразователя и преобразователя давления на обратном трубопроводе, м"
                paid={r.lenobr !== '1,5'}>
                <OptionButtons options={toOpt(['1,5', '3', '6', '10', '15'], true)} onClick={set('LENOBR')}
                  selected={r.lenobr} />
                {getPage(4)}
              </Row>
            }
          </Group>
          {
            (r.count >= 2) &&
            <Group label="2. Расходомер 2">
              <Row setMissing={setMissing} label="Диаметр условного прохода, мм"
                req={r.dia2 === undefined || r.dia2 === ''}>
                <SelectOptions options={Object.keys(optionsByDia).map(el => {
                  return { id: el, text: el }
                })} selected={r.dia2} onClick={set('DIA2')} />
              </Row>
              <Row setMissing={setMissing} label="Максимальный объемный расход, м³/ч"
                req={r.volume2 === undefined || r.volume2 === ''}>
                <OptionButtons options={volumeTable2} onClick={set('VOLUME2')} selected={r.volume2} />
              </Row>
              {
                (table2dia2filled && t2dia2 !== undefined) ?
                  <>
                    <Row label="Минимальный объемный расход, м³/ч">
                      {t2dia2.minim}
                    </Row>
                    <Row label="Предельный объемный расход, м³/ч">
                      {t2dia2.limit}
                    </Row>
                    <Row label="Порог чувствительности, м³/ч">
                      {t2dia2.por}
                    </Row>
                  </>
                  : false
              }

              {((r.type === "06" || r.type === "07") && r.energymethod === "3") &&
                <Row setMissing={setMissing} label="Обратный поток" paid={r.obr2 !== false}>
                  <Switch checked={r.obr2 !== false} onChange={(event) => set('OBR2')(event.target.checked)}
                    color="primary" />
                </Row>
              }
              {/* <Row setMissing={setMissing} label="Длина кабеля от преобразователя расхода до вычислителя, м"
                paid={r.lenv2 !== '0,3'}>
                <OptionButtons options={toOpt(['0,3', '1,5', '3', '6'], true)} onClick={set('LENV2')}
                  selected={r.lenv2} />
                {getPage(5)}
              </Row> */}
                <Row setMissing={setMissing} label="Выносной индикатор 6м">
              <Switch checked={r.ind2 !== false} onChange={(event) => set('IND2')(event.target.checked)}
                color="primary" />
            </Row>
            </Group>
          }
          {
            (r.count >= 3) &&
            <Group label="3. Счетчик воды ультразвуковой">
              <Row setMissing={setMissing} label="Диаметр условного прохода, мм"
                req={r.dia3 === undefined || r.dia3 === ''}>
                <SelectOptions options={Object.keys(optionsByDia).map(el => {
                  return { id: el, text: el }
                })} selected={r.dia3} onClick={set('DIA3')} />
              </Row>
              <Row setMissing={setMissing} label="Номинальный объемный расход воды, м³/ч"
                req={r.volume3 === undefined || r.volume3 === ''}>
                <OptionButtons options={volumeTable3} onClick={set('VOLUME3')} selected={r.volume3} />
              </Row>
              {
                (table3filled && t3 !== undefined) ?
                  <>
                    <Row label="Минимальный объемный расход, м³/ч"
                      req={r.t3min === undefined || r.dia3 === ''}>
                      <OptionButtons options={t3minim} onClick={set('T3MIN')} selected={r.t3min} />
                      {/* {t2.minim} */}
                    </Row>
                    <Row label="Максимальный объемный расход, м³/ч">
                      {/* req={r.limit === undefined || r.dia === ''}>
              <OptionButtons options={tlimit} onClick={set('LIMIT')} selected={r.limit}/> */}
                      {t3.maxim}
                    </Row>
                    <Row label="Переходный (для исполнения 1), м³/ч">
                      {t3.per}
                    </Row>
                    <Row label="Порог чувствительности, м³/ч">
                      {t3.por}
                    </Row>
                  </>
                  : false
              }


              {/* <Row setMissing={setMissing} label="Обратный поток" paid={r.obr3 !== false}>
                <Switch checked={r.obr3 !== false} onChange={(event) => set('OBR3')(event.target.checked)}
                        color="primary"/>
              </Row> */}
              {/* <Row setMissing={setMissing} label="Длина кабеля от преобразователя расхода до вычислителя, м"
                paid={r.lenv3 !== '0,3'}>
                <OptionButtons options={toOpt(['0,3', '1,5', '3', '6'], true)} onClick={set('LENV3')}
                  selected={r.lenv3} />
                {getPage(6)}
              </Row> */}
                <Row setMissing={setMissing} label="Выносной индикатор 6м">
              <Switch checked={r.ind3 !== false} onChange={(event) => set('IND3')(event.target.checked)}
                color="primary" />
            </Row>
            </Group>
          }
          <Group label={`${rcount + 1}. Дополнительная комплектация`}>
            <Typography>
              Для монтажа:
            </Typography>
            <Table opt={r.dia && parseInt(r.dia) >= 80 ? kran_opt_special : kran_opt} table={'table_kran'} label="Арматура для термопреобразователя в обратный трубопровод ³"
              r={r} dispatch={dispatch} setMissing={setMissing} def caption={"Укажите диаметр условного прохода обратного трубопровода³·¹"} />
            <Row setMissing={setMissing} label={<Code code={"Н00037255"} title={"Ключ для монтажа термометров³·³"} />}>
              <SwitchCount count={r.dopkey} setCount={set('DOPKEY')} />
            </Row>
            <Table opt={rezba_opt} table={'table_rezba'} label="Установочный присоединительный комплект (латунь, резьбовое присоединение)"
              r={r} dispatch={dispatch} setMissing={setMissing} />
            <Table opt={privarka_opt} table={'table_privarka'} label="Установочный присоединительный комплект под приварку"
              r={r} dispatch={dispatch} setMissing={setMissing} />
            {/*<Row setMissing={setMissing} label={<Code title={"Радиатор-охладитель для датчиков давления"} />}>*/}
            {/*  <SwitchCount count={r.dop4} setCount={set('DOP4')}/>*/}
            {/*</Row>*/}
            <Table opt={radiator_opt} table={'table_radiator'} label="Радиатор-охладитель для датчиков давления"
              r={r} dispatch={dispatch} setMissing={setMissing} maxOptions={2} />
            <Typography>
              Для снятия архивов:
            </Typography>
            <Row setMissing={setMissing} label={<Code code={"Н00002740"} title={"Конвертер RS 485/USB"} />} >
              <SwitchCount count={r.dop3} setCount={set('DOP3')} />
            </Row>
            <Row setMissing={setMissing} label={<Code code={"Н00083759"} title={'ПО "Пульсар" (с программной защитой)'} />} >
              <SwitchCount count={r.dop7} setCount={set('DOP7')} />
            </Row>
            <Typography>
              Для организации удаленного съема данных:
            </Typography>
            <Row setMissing={setMissing} label={<Code code={"Н00003739"} title={'GSM/GPRS Модем "Пульсар"'} />} >
              <SwitchCount count={r.dop5} setCount={set('DOP5')} />
            </Row>
            <Row setMissing={setMissing} label={<Code code={"Н00002738"} title={"Преобразователь RS232/RS485 - Ethernet"} />} >
              <SwitchCount count={r.dop6} setCount={set('DOP6')} />
            </Row>
            <Row setMissing={setMissing} label={<Code code={"Н00005873"} title={"Источник питания ИП 12-6"} />} >
              <SwitchCount count={r.dop8} setCount={set('DOP8')} />
            </Row>
            <Row setMissing={setMissing} label={<Code code={"Н00002025"} title={'Антенна "АНТЕЙ"'} />} >
              <SwitchCount count={r.dop9} setCount={set('DOP9')} />
            </Row>
          </Group>
          <Group label={`${rcount + 2}. Примечания`} initExpanded>
            <Typography variant="caption">(1) В модификации теплосчетчика с одним расходомером преобразователь расхода
              устанавливается в прямом или обратном трубопроводе. В модификациях теплосчетчиков с двумя расходомерами,
              расходомер - тепловычислитель, совмещенный с вычислителем, устанавливается в подающий
              трубопровод.</Typography>
            <Typography variant="caption">(2) Для приборов типа 5-10 невозможно исполнение с 2-мя датчиками давления и
              «обратным потоком»</Typography>
            <Typography variant="caption">(!) Теплосчетчик с одним расходомером имеет 3 дополнительных импульсных входа,
              с двумя расходомерами – 2 импульсных входа, с тремя - 1 импульсный вход.</Typography>
            <Typography variant="caption">(3) По умолчанию считается, что диаметр условного прохода обратного трубопровода
              равен диаметру расходомера – тепловычислителя и в пункте 2. Дополнительная комплектация, в разделе
              «Арматура для термопреобразователя в обратный трубопровод» автоматически устанавливается соответствующий
              диаметр условного прохода обратного трубопровода.
            </Typography>
            <Typography variant="caption">(3.1) Если диаметр условного прохода обратного трубопровода отличается от
              диаметра условного прохода расходомера – тепловычислителя, то в пункте
              2. Дополнительная комплектация в разделе «Арматура для термопреобразователя в обратный трубопровод»
              необходимо указать нужный диаметр условного прохода обратного трубопровода.
            </Typography>
            <Typography variant="caption">
              (3.2) Если арматура для термопреобразователя в обратный трубопровод не требуется – отменить выбор данной опции.
            </Typography>
            <Typography variant="caption">
              (3.3) Если ключ для монтажа термометров в ГТЗ не требуется – отменить выбор данной опции.
            </Typography>
          </Group>
          <Group label={`${rcount + 3}. Расчёт`} initExpanded>
            {(regionId !== -1 &&
              <>
                <Row label="Ваш персональный менеджер">
                  <Typography className={classes.bold}>{regionData.manager}</Typography>
                </Row>
                <Row label="Телефон">
                  <Typography className={classes.bold}>{regionData.phone}</Typography>
                </Row>
                <Row label="E-Mail">
                  <Typography className={classes.bold}><a
                    href={`mailto:${regionData.email}`}>{regionData.email}</a></Typography>
                </Row>
              </>
            )
            }
            <Row label="Комментарий менеджеру (опционально)">
              <Text onBlur={(e) => set('S_COMMENT')(e.target.value)} text={r.s_comment}
                placeholder="Дополнительный комментарий" />
            </Row>
            <Row setMissing={setMissing} label=''>
              {saving !== '' && <div><Typography className={classes.bold}>{saving}</Typography></div>}
              {
                <MyButton loading={savingProgress} variant="contained" color="primary" onClick={() => {
                  if (missing.length > 0)
                    setOpenMissing(true);
                  else
                    savePDF();
                }}>
                  {savingProgress ? 'Отправка' : saving !== '' ? 'Отправить повторно' : 'Отправить расчёт'}
                </MyButton>
              }
            </Row>
            {/*<Row label="Ссылка">*/}
            {/*  <a href={`${baseApiUrl}/send/pdf/${objectToHash(r)}`} >PDF</a>*/}
            {/*</Row>*/}
          </Group>
        </Paper>
      </main>
      {openMissing && <RequiredFieldDialog open={openMissing} setOpen={setOpenMissing} fields={missing} />}
      <MaxWidthDialog open={openHelp} setOpen={(v) => {
        setOpenHelp(v);
        if (v === false) {
          setTimeout(() => {
            firstInputRef.current.focus();
            //console.log(firstInputRef.current);
          }, 500);
        }
      }
      } />
      <HelpPage open={openPage} setOpen={setOpenPage} src={pageSrc} />

    </div>

  }

  return content;
}

export default App;
