export const activities = [
    "Проектная организация",
    "Застройщик",
    "Монтажная организация",
    "Поставщик оборудования",
    "Управляющая компания",
    "Газоснабжающая организация",
    "Электроснабжающая организация",
    "Теплоснабжающая организация",
    "Водоснабжающая организация"
]
