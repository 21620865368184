import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function RegionSelect(props) {
  return (
    <Autocomplete
      id="combo-box-regions"
      options={props.regions.sort( (a,b) => a.name > b.name ? 1 : a.name<b.name ? -1 : 0).map( (el) => el.name)}
      getOptionLabel={(option) => option}
      style={{ width: '100%' }}
      {...props}
    />
  );
}
