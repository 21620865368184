import {useStyles} from "./Styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

export function SelectOptions({options, onClick, selected, withClear}) {
  const classes = useStyles();

  const handleChange = (event) => {
    if (onClick)
      onClick(event.target.value);
  };

  return (
    <Select
      value={selected}
      onChange={handleChange} className={classes.min200}
    >
      <MenuItem aria-label="None" value="">{ withClear === true ? "<удалить>" : ""}</MenuItem>
      {
        options.map(({id, text}) => (
          <MenuItem value={id} key={id}>{text}</MenuItem>
        ))
      }
    </Select>
  );
}
