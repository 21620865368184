import React from 'react';
import {Button, CircularProgress} from "@material-ui/core";

export function MyButton({ loading, children, ...other}) {
  const childs = [...children];
  if (loading)
    childs.unshift(<CircularProgress size={28} />);

  return <Button
    disabled={loading}
    {...other}
  >
    {childs}
  </Button>;
}
