import Switch from "@material-ui/core/Switch";
import * as PropTypes from "prop-types";
import React, {useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import {useStyles} from "./Styles";

export function SwitchCount({ count, setCount}) {
  const inputEl = useRef(null);
  const classes = useStyles();
  useEffect(
    () => {
      if (inputEl.current)
        inputEl.current.value = count;
    },
    [count]
  );

  return <div><Switch checked={ (count !== '' && count !== undefined && parseInt(count)!==0) } onChange={(event) => {
    if (event.target.checked)
        setCount(1);
    else
        setCount(0);
  }} color="primary"/>
  {
    (count !== '' && count !== undefined && parseInt(count)!==0)  && <>
  <TextField onBlur={(e) => setCount(e.target.value)}
                                  placeholder="Количество" inputProps={{ref: inputEl}}  className={classes.countField}
  /> шт.</>
  }
  </div>;
}

SwitchCount.propTypes = {
  count: PropTypes.any,
  setCount: PropTypes.func
};
