import {baseApiUrl} from './constants';

function getApi(url, body, method) {
  const request = new Request(`${baseApiUrl}/${url}`, {
    method: method || 'GET',
    body: JSON.stringify(body),
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return response.json();
    });
}

export const Api = {
  settings: () => getApi(`domains/settings`)
};
