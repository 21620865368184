import React from "react";
import {useStyles} from "./Styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";

export function Group({children, label, initExpanded = false}) {
  const [expanded, setExpanded] = React.useState(initExpanded);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const classes = useStyles();
  return (
    <Accordion className={classes.fullWidth} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.AccordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
