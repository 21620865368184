import {Typography} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import {Code} from "./Code";
import {SelectOptions} from "./SelectOptions";
import TextField from "@material-ui/core/TextField";
import {useStyles} from "./Styles";
import {Row} from "./Row";
export function RowWithCount({ opt, count, du, setTableDu, setTableCount, ...others}) {

  const inputEl = useRef(null);
  const classes = useStyles();
  useEffect(
    () => {
      if (inputEl.current)
        inputEl.current.value = count;
    },
    [count]
  );
  const info = opt?.find( (el) => el.id === du);
  return info?.desc ? (
    <>
      <Row label={<SelectOptions options={opt} onClick={setTableDu} selected={du || ""} withClear={!!count}/>} table/>
      <Row label={<Code code={info?.code || ""} title={info?.desc || ""} />} table>
        <TextField onBlur={(e) => setTableCount(+e.target.value)}
                   placeholder="99" className={classes.countField} inputProps={{ref: inputEl}}/> шт.
      </Row>
    </>
    )
    : (<Row label={<SelectOptions options={opt} onClick={setTableDu} selected={du || ""} withClear={!!count}/>} table={!!(opt?.[0]?.desc)}>
      <TextField onBlur={(e) => setTableCount(+e.target.value)}
                 placeholder="99" className={classes.countField} inputProps={{ref: inputEl}}
      /> шт.
    </Row>);
}