import React from "react";
import { Row } from "./Row";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { RowWithCount } from "./RowWithCount";

export function Table({ setMissing, opt, r, table, dispatch, label, def, maxOptions, caption }) {
  let option = [...opt];
  const [switchCh, setSwitchCh] = React.useState(!!def);

  const setTableDu = (row) => (du) => dispatch({ type: 'TABLE_SET', table, row, du });
  const setTableCount = (row) => (quantity) => dispatch({ type: 'TABLE_SET', table, row, quantity });
  const clearTable = () => dispatch({ type: 'TABLE_DROP', table });

  return <>
    <Row setMissing={setMissing} label={label}>
      <Switch checked={ switchCh } onChange={(event) => {
        setSwitchCh(event.target.checked)
        if (!event.target.checked)
          clearTable()
      }} color="primary"/>
    </Row>
    {
      switchCh &&
      <>
        <Typography variant="caption">{ caption || `Укажите диаметр условного прохода:`}</Typography>
        {
          r[table].map((row, id) => {
            const element = r[table]?.[id];
            const rows = [
              <RowWithCount key={`frag_${table}_${id}`} setTableDu={setTableDu(id)}
                            du={element?.du}
                            count={element?.quantity} setTableCount={setTableCount(id)}
                            opt={[...option]}/>
            ];
            option = option.filter((el) => el.id !== element?.du);
            return rows;
          })
        }
        {option.length > 0 && (!maxOptions ||  r[table].length<maxOptions) &&
        <RowWithCount setTableDu={setTableDu(r[table]?.length || 0)} du={''}
                      count={''} setTableCount={setTableCount(r[table]?.length || 0)}
                      opt={option}/>
        }
      </>
    }
  </>

}
