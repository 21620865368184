import {useStyles} from "./Styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

export function Row({setMissing, children, label, req, paid, table}) {
  const classes = useStyles();
  if (req === true)
    setMissing(label);
  const sm = table ? 10 : 6;

  return (
    <Grid container className={classes.row}>
      <Grid item sm={sm} xs={12}>
        <Typography className={classes.bold}>
          {label}
        </Typography>
        { req !== undefined ?
          <Typography className={req ? classes.reqTrue : classes.reqFalse }>* необходимо заполнить</Typography> : '' }
        { paid !== undefined ?
          <Typography className={paid ? classes.paidTrue : classes.paidFalse }>* дополнительно оплачиваемая опция</Typography> : '' }
      </Grid>
      <Grid item sm={12-sm} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}
