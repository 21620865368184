import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ActivitySelect(props) {
  return (
    <Autocomplete
      id="combo-box-regions"
      options={props.activities.sort()}
      getOptionLabel={(option) => option}
      style={{ width: '100%' }}
      {...props}
    />
  );
}
