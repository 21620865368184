import React, {useEffect, useRef} from "react";
import TextField from "@material-ui/core/TextField";
import MaskedInput from 'react-text-mask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['+','7','(', /[01234569]/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'_'}
      placeholder={'+7(999)999-9999'}
      guide={false}
    />
  );
}

export function Text({onBlur, text, mask, fullWidth, ...others}) {
  const inputEl = useRef(null);

  useEffect(
    () => {
      if (inputEl.current)
        inputEl.current.value = text;
    },
    [text]
  );

  let InputProps = {};
  let inputProps = {ref: inputEl};
  if (mask) {
    InputProps={ref: inputEl, inputComponent: TextMaskCustom,
      value: text,
      onChange: onBlur,
    };
    inputProps = undefined;
  }
  return <TextField onBlur={onBlur} fullWidth={fullWidth ?? true} {...others} inputProps={inputProps}
                    InputProps={InputProps}
  />;
}
