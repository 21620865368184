import { useStyles } from "./Styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import React from "react";

export function OptionButtons({ options, onClick, selected, size = "medium" }) {
  const classes = useStyles();
  // React.useEffect(() => {
  //    if (options && options[0] && !options.find( (el) => el.id === selectedIndex ))
  //      onSelect(options[0].id);
  // }, [options]);

  const handleMenuItemClick = (event, index) => {
    if (onClick !== undefined)
      onClick(index);
  };

  return (
    <ButtonGroup size={size} color="primary" aria-label="outlined primary button group" className={classes.buttonGroup}>
      {
        options.map(({ id, text, paid }) => (
          <Button key={id} variant={selected === id ? "contained" : "outlined"}
            onClick={(e) => handleMenuItemClick(e, id)}
          // endIcon={paid ? <AttachMoney/> : undefined}
          >{text}</Button>
        ))
      }
    </ButtonGroup>
  );
}
